import React from 'react'
import Img from 'gatsby-image'

const MarkdownImage = ({ rehyped, className }) => {
  const props = JSON.parse(rehyped);

  return (
    <Img
      fluid={props}
      className={className}
    />
  );
};

export default MarkdownImage