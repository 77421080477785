import React from "react";
import { useTranslation } from "react-i18next";
import rehypeReact from 'rehype-react'
import MarkdownImage from '../MarkdownImage'
import './tender.scss'

export default ({ data, content }) => {

    const { t } = useTranslation()
    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: { 'markdown-image': MarkdownImage },
    }).Compiler

    return(
        <div id="tender" class="blog-area blog-no-sidebar pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                        <div class="blog-wrapper blog-details">
                            <div class="blog-content">
                                { renderAst(content) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}